import React, { useState, useEffect } from 'react'
import { useStaticQuery, graphql, Link } from 'gatsby'
import styled, { css } from 'styled-components'
import ProgressiveImage from 'react-progressive-image'
import { forEach } from 'lodash'
import { useMeasure } from 'react-use'
import ReactPlayer from 'react-player'

import { Layout, PageTitle, Slider } from '../components'
import { container, padding, bgImage, bgIcon, type } from '../styles/global'
import { parseACF } from '../utils'
import { media } from '../styles/utils'

import videoPlay from '../assets/icons/video-play.svg'

const ProjectSingle = (props) => {
    const data = props?.pageContext?.data
    const [slideImageRef, { height: slideImageHeight }] = useMeasure()
    const [videoPlaying, setVideoPlaying] = useState(false)
    console.log(data)

    let years = data?.start_year
    if (data.other_years) {
        forEach(data.other_years, (item, key) => {
            years = years + `, ${item.year}`
        })
    }

    let locations
    if (data?.locations?.length) {
        forEach(data.locations, (item, key) => {
            if (locations) {
                locations += `${item.location}, ${item.city}<br/>`
            } else {
                locations = `${item.location}, ${item.city}<br/>`
            }
        })
    }

    const renderIntro = () => {
        return (
            <IntroSection>
                <Intro>
                    <Item>
                        <Label>Year:&nbsp;</Label>
                        <Value>{years}</Value>
                    </Item>
                    <Item>
                        <Label>Format:&nbsp;</Label>
                        <Value>{data?.format}</Value>
                    </Item>
                </Intro>
            </IntroSection>
        )
    }

    const renderSlides = (slides) => {
        return slides.map((item, i) => {
            const image = item?.image?.sizes?.medium2

            return (
                <Slide key={i}>
                    {image && (
                        <ImageWrapper 
                            //style={{ backgroundColor: '#ccc' }}
                        >
                            <BGImage
                                ref={i === 0 ? slideImageRef : null}
                                image={image}
                                landscape={image.height < image.width}
                            />
                        </ImageWrapper>
                    )}
                    {item.use_caption && item.caption && (
                        <Caption
                            dangerouslySetInnerHTML={{
                                __html: item.caption,
                            }}
                        />
                    )}
                </Slide>
            )
        })
    }

    const renderSlider = () => {
        if (!data.image_slider) return

        return (
            <Slider
                slides={data.image_slider}
                renderSlides={renderSlides}
                slideImageHeight={slideImageHeight}
            />
        )
    }

    const getVideoURL = () => {
        if (!data.video_type) return false

        switch (data.video_type) {
            case 'embed':
                if (data.video_embed && data.video_embed.match(/src="(.+?)"/)) {
                    return data.video_embed.match(/src="(.+?)"/)[1]
                } else {
                    return false
                }
                break
            case 'mp4_file':
                if (data.video_mp4_file) {
                    return data.video_mp4_file
                } else {
                    return false
                }
                break
            case 'mp4_link':
                if (data.video_mp4_external_link) {
                    return data.video_mp4_external_link
                } else {
                    return false
                }
            default:
                return false
                break
        }
    }

    const renderVideo = () => {
        if (!data.video_display || !getVideoURL()) return

        return (
            <VideoSection>
                <VideoWrapper>
                    <VideoSizer>
                        <Video
                            url={getVideoURL()}
                            playing={videoPlaying}
                            controls={true}
                            volume={1}
                            width="100%"
                            height="100%"
                            light={
                                data.video_image_override
                                    ? data.video_image_override?.sizes?.medium2
                                    : true
                            }
                            onReady={() => setVideoPlaying(true)}
                            onPause={() => setVideoPlaying(false)}
                            onEnded={() => setVideoPlaying(false)}
                        />
                    </VideoSizer>
                </VideoWrapper>
                {data.video_use_caption && data.video_caption && (
                    <Caption
                        dangerouslySetInnerHTML={{
                            __html: data.video_caption,
                        }}
                    />
                )}
            </VideoSection>
        )
    }

    const renderDescription = () => {
        if (!data.description) return

        let mediaItems
        if (data.media_links) {
            mediaItems = data.media_links.map((item, i) => {
                if (!item.url) return false
                return (
                    <Item key={i}>
                        <Dot />
                        <ExternalLink key={i} href={item.url} target="_blank">
                            {item.link_text ? item.link_text : item.url}
                        </ExternalLink>
                    </Item>
                )
            })
        }

        return (
            <DescriptionSection>
                <Heading>Project Description</Heading>
                <Description
                    dangerouslySetInnerHTML={{
                        __html: data.description,
                    }}
                />
                {mediaItems && (
                    <MediaLinks>
                        <Subheading>Media</Subheading>
                        <Items>{mediaItems}</Items>
                    </MediaLinks>
                )}
            </DescriptionSection>
        )
    }

    const renderCreditItems = (items) => {
        return items.map((item, i) => {
            return (
                <Item key={i}>
                    <Label
                        dangerouslySetInnerHTML={{
                            __html: item.what,
                        }}
                    />
                    <Value
                        dangerouslySetInnerHTML={{
                            __html: item.who,
                        }}
                    />
                </Item>
            )
        })
    }

    const renderCredits = () => {
        if (!data.credits) return

        let creditItems
        if (data.credits) {
            creditItems = data.credits.map((item, i) => {
                if (!item.credit_lines) return false
                return (
                    <>
                        {item.heading && (
                            <Subheading>{item.heading}</Subheading>
                        )}
                        <Items>{renderCreditItems(item.credit_lines)}</Items>
                    </>
                )
            })
        }
        return (
            <CreditsSection>
                <Heading>Project Credits</Heading>
                <Credits>{creditItems}</Credits>
            </CreditsSection>
        )
    }

    const renderCommissioned = () => {
        if (!data.commissioned) return

        return (
            <CommissionedSection>
                <Info>
                    <Label>Commissioned by:</Label>
                    <Value
                        dangerouslySetInnerHTML={{
                            __html: data.commissioned,
                        }}
                    />
                </Info>
            </CommissionedSection>
        )
    }

    return (
        <Layout meta={data && data.seo}>
            <Wrapper>
                <PageTitle title={data.page_title_override || data.title} />
                <Container>
                    {renderIntro()}
                    {renderSlider()}
                    {renderVideo()}
                    {renderDescription()}
                    {renderCredits()}
                    {renderCommissioned()}
                </Container>
            </Wrapper>
        </Layout>
    )
}

// Shared

const Heading = styled.div``
const Subheading = styled.div``
const Label = styled.div``
const Value = styled.div``
const Description = styled.div``
const Info = styled.div``
const Items = styled.div``
const Item = styled.div``
const Caption = styled.div``
const ExternalLink = styled.a``

const Image = styled(ProgressiveImage)`
    overflow: hidden;
`

const BGImage = styled.div`
    background-image: url(${(props) => props.image});
    ${bgImage};
    transition: opacity 0.45s ease;
    ${props => {
        if(!props.landscape) return css`
            ${bgIcon}
        `
    }}
`

const Icon = styled.img``

// Layout

const Wrapper = styled.div`
    width: 100%;
`

const Container = styled.div`
    ${container}
    ${padding}
`

// Intro Section

const IntroSection = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
`

const Intro = styled.div`
    max-width: 1120px;
    padding-top: 56px;
    padding-bottom: 62px;

    ${media.phone`
        padding-top: 29px;
    `}

    ${Item} {
        display: flex;
        justify-content: flex-start;

        ${media.phone`
            &:not(:first-child) {
                margin-top: 8px;
            }
        `}

        ${Label} {
            min-width: 200px;
            padding-right: 32px;

            ${media.phone`
                min-width: 130px;
                padding-right: 24px;
            `}
        }

        &,
        * {
            ${type.heading1}
        }
    }
`

// Slider Section

const ImageWrapper = styled.div``
const Slide = styled.div`
    ${ImageWrapper} {
        position: relative;
        width: 100%;
        height: 0;
        margin-bottom: 58px;
        padding-bottom: 70.06%;

        ${media.phone`
            margin-bottom: 36px;
        `}

        ${BGImage} {
            position: absolute;
            top: 0;
            left: 0;
            right: 0;
            bottom: 0;
            width: 100%;
            height: 100%;
        }
    }

    ${Caption} {
        ${type.heading2}
        text-align: center;

        ${media.phone`
            font-size: 14px;
            line-height: 22px;
        `}
    }
`

// Video Section

const Video = styled(ReactPlayer)``
const VideoWrapper = styled.div``
const VideoSizer = styled.div``
const PlayToggle = styled.div``
const VideoSection = styled.div`
    ${VideoWrapper} {
        width: 766px;
        max-width: 100%;
        margin: 0 auto;
    }

    ${VideoSizer} {
        position: relative;
        width: 100%;
        height: 0;
        margin-bottom: 53px;
        padding-top: 56.25%;
        overflow: hidden;

        ${media.phone`
            margin-bottom: 33px;
        `}
    }

    ${Video} {
        width: 100% !important;
        height: 100% !important;
    }

    ${Video} {
        position: absolute;
        top: 0;
        left: 0;
    }

    .react-player__preview {
        cursor: url(${videoPlay}), auto !important;

        .react-player__shadow,
        .react-player__play-icon {
            display: none !important;
        }

        ${media.phone`
            position: relative;

            &::before {
                z-index: 9;
                content: 'Play';
                position: absolute;
                top: 50%;
                left: 50%;
                transform: translate(-50%, -50%);
                font-size: 24px;
                line-height: 28px;
                letter-spacing: 0.2px;
                color: white;
            }
        `}
    }

    ${Caption} {
        ${type.heading2}
        text-align: center;

        ${media.phone`
            font-size: 14px;
            line-height: 22px;
        `}
    }
`

// Description Section

const MediaLinks = styled.div``
const Dot = styled.div``

const DescriptionSection = styled.div`
    display: flex;
    flex-direction: column;
    width: 50%;
    margin-top: 171px;
    margin-bottom: 241px;

    ${media.phone`
        width: 100%;
        margin-top: 111px;
        margin-bottom: 158px;
    `}

    ${Heading} {
        text-align: center;
        ${type.heading1}

        ${media.phone`
            text-align: left;
            margin-left: 20px;
        `}
    }

    ${Description} {
        margin-top: 65px;

        ${media.phone`
            margin-top: 32px;
        `}

        > *:not(:first-child) {
            margin-top: 1em;
        }
    }

    ${MediaLinks} {
        display: flex;
        margin-top: 65px;

        ${media.phone`
            display: block;
            margin-top: 48px;
        `}

        ${Subheading} {
            margin-right: 82px;

            ${media.phone`
                margin-right: 0;
                margin-bottom: 10px;
            `}
        }

        ${Items} {
            display: flex;
            flex-direction: column;
        }

        ${Item} {
            display: flex;
            align-items: center;

            &:not(:first-child) {
                margin-top: 6px;
            }

            ${Dot} {
                display: flex;
                width: 15px;
                height: 15px;
                border-radius: 50%;
                background: black;
            }

            ${ExternalLink} {
                margin-left: 21px;
                padding-bottom: 2px;
                border-bottom: 1px solid black;

                ${media.phone`
                    margin-left: 13px;
                `}
            }
        }
    }
`

// Credits Section

const Credits = styled.div``
const CreditsSection = styled.div`
    margin-bottom: 304px;

    ${media.phone`
        margin-bottom: 80px;
    `}

    ${Heading} {
        width: 50%;
        text-align: center;
        ${type.heading1}

        ${media.phone`
            text-align: left;
            margin-left: 20px;
        `}
    }

    ${Credits} {
        margin-top: 77px;

        ${media.phone`
            margin-top: 44px;  
        `}
    }

    ${Subheading} {
        margin-bottom: 21px;
        ${type.heading2}

        ${media.phone`
            margin-bottom: 27px;
        `}
    }

    ${Items} {
        &:not(:last-child) {
            margin-bottom: 47px;
        }

        ${Item} {
            display: flex;
            padding: 15px 0;
            border-top: 2px solid black;

            &:last-child {
                border-bottom: 2px solid black;
            }

            &,
            * {
                ${type.heading4}
            }

            ${Label}, ${Value} {
                flex-basis: 25%;

                ${media.tablet`
                    flex-basis: 50%;
                `}
            }

            ${Label} {
                padding-right: 32px;

                ${media.phone`
                    padding-right: 18px;
                `}
            }
        }
    }
`

// Commissioned Section

const CommissionedSection = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-bottom: 232px;

    ${media.phone`
        margin-bottom: 21px;
    `}

    ${Info} {
        display: flex;
        max-width: 1120px;

        ${media.phone`
            display: block;
        `}

        &,
        * {
            //${type.heading1}
        }

        ${Label} {
            flex-grow: 1;
            flex-shrink: 0;
            padding-right: 48px;

            ${media.phone`
                padding-right: 0;
                margin-bottom: 9px;
            `}
        }

        ${Value} {
            flex-shrink: 1;
            flex-grow: 0;

            ${media.phone`
                &, * {
                    line-height: 1.35;
                }
            `}
        }
    }
`

export default ProjectSingle
